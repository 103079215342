// src/components/Inbox.js
import React from 'react';
import { Link } from 'react-router-dom';

function Inbox() {
  const emails = [
    { id: 1, subject: 'Welcome!', sender: 'admin@example.com' },
    { id: 2, subject: 'Meeting Reminder', sender: 'manager@example.com' },
  ];

  return (
    <div>
      <h2>Inbox</h2>
      <ul>
        {emails.map(email => (
          <li key={email.id}>
            <Link to={`/email/${email.id}`}>
              <strong>{email.sender}</strong> - {email.subject}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Inbox;
