import React, { useState } from 'react';
import '../Css/Navbar.css';
import { FaBars, FaCog, FaEnvelope, FaSearch, FaMoon, FaSun } from 'react-icons/fa';

function Navbar({ onToggleSidebar, onToggleTheme, isDarkMode }) {
  const [searchActive, setSearchActive] = useState(false);

  const toggleSearch = () => {
    setSearchActive(!searchActive);
  };

  return (
    <nav className="navbar">
      <div className="navbar-left">
        <button className="toggle-btn" onClick={onToggleSidebar}>
          <FaBars />
        </button>

        <div className="logo">
          <img src="https://via.placeholder.com/40" alt="Email Client Logo" />
        </div>
      </div>

      <div className={`search-box ${searchActive ? 'active' : ''}`}>
        <button className="btn-search" onClick={toggleSearch}><FaSearch /></button>
        <input type="text" className="input-search" placeholder="Search" />
      </div>

      <div className="navbar-right">
        <button className="icon-btn">
          <FaCog />
        </button>
        <button className="icon-btn">
          <FaEnvelope />
        </button>

        {/* Toggle button for dark/light mode */}
        <button className="icon-btn" onClick={onToggleTheme}>
          {isDarkMode ? <FaSun /> : <FaMoon />}
        </button>
      </div>
    </nav>
  );
}

export default Navbar;
