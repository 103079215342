import React from 'react';
import '../Css/Compose.css';
import { FaPaperclip, FaImage, FaLink, FaSmile, FaTrashAlt, FaPaperPlane } from 'react-icons/fa';

function Compose({ onClose, isDarkMode }) {
  return (
    <div className={`compose-popup ${isDarkMode ? 'dark' : 'light'}`}>
      <div className="compose-content">
        <div className="compose-header">
          <h2>New Message</h2>
          <button className="close-btn" onClick={onClose}>×</button>
        </div>

        <div className="compose-body">
          <label>To:</label>
          <input type="email" placeholder="Recipient's email" />

          <label>From:</label>
          <input type="text" placeholder="youremail@example.com" />

          <label>Subject:</label>
          <input type="text" placeholder="Subject" />

          <label>Message:</label>
          <textarea placeholder="Write your message here..."></textarea>
        </div>

        <div className="compose-footer">
          <button className="send-btn"><FaPaperPlane /> Send</button>
          <button className="icon-btn"><FaPaperclip /></button>
          <button className="icon-btn"><FaImage /></button>
          <button className="icon-btn"><FaLink /></button>
          <button className="icon-btn"><FaSmile /></button>
          <button className="delete-btn" onClick={onClose}><FaTrashAlt /></button>
        </div>
      </div>
    </div>
  );
}

export default Compose;
