// src/components/EmailDetail.js
import React from 'react';

function EmailDetail() {
  // Placeholder for email data
  const email = { sender: 'admin@example.com', subject: 'Welcome!', body: 'Thanks for joining us!' };

  return (
    <div>
      <h2>Email Detail</h2>
      <p><strong>From:</strong> {email.sender}</p>
      <p><strong>Subject:</strong> {email.subject}</p>
      <p>{email.body}</p>
    </div>
  );
}

export default EmailDetail;
