import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import MainContent from './components/mainContent';
import Sidebar from './components/Sidebar';
import Inbox from './components/Inbox';
import Compose from './components/Compose';
import EmailDetail from './components/EmailDetail';
import './App.css';

function App() {
  const [isComposeOpen, setIsComposeOpen] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(false);

  // Toggle dark mode
  const toggleTheme = () => {
    setIsDarkMode(!isDarkMode);
  };

  const openCompose = () => {
    setIsComposeOpen(true);
  };

  const closeCompose = () => {
    setIsComposeOpen(false);
  };

  return (
    <Router>
      <div className={`app-container ${isDarkMode ? 'dark-theme' : 'light-theme'}`}>
        {/* Pass theme toggle and dark mode state to Navbar */}
        <Navbar
          onToggleSidebar={openCompose}
          onToggleTheme={toggleTheme}
          isDarkMode={isDarkMode}
        />
        <div className="app-layout">
          {/* Pass dark mode state to Sidebar */}
          <Sidebar onComposeClick={openCompose} isDarkMode={isDarkMode} />
          <div className="main-content">
            <Routes>
              <Route path="/" element={<MainContent isDarkMode={isDarkMode} />} />
              <Route path="/inbox" element={<Inbox />} />
              <Route path="/email/:id" element={<EmailDetail />} />
            </Routes>
          </div>
        </div>
        {/* Pass dark mode state to Compose */}
        {isComposeOpen && <Compose onClose={closeCompose} isDarkMode={isDarkMode} />}
      </div>
    </Router>
  );
}

export default App;
