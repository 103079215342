// src/components/MainContent.js
import React, { useState } from 'react';
import { FaArchive, FaExclamationTriangle, FaTrash, FaStar, FaEnvelope } from 'react-icons/fa';  // Removed FaClock
import { AiOutlineInbox, AiOutlineTag, AiOutlineUser } from 'react-icons/ai';
import '../Css/MainContent.css';

function MainContent({ isDarkMode }) {
  const [emails, setEmails] = useState([
    { id: 1, sender: "John Doe", title: "Project Update", time: "10:30 AM" },
    { id: 2, sender: "Jane Smith", title: "Meeting Schedule", time: "9:15 AM" },
    { id: 3, sender: "Company Newsletter", title: "Monthly Updates", time: "Yesterday" },
    { id: 4, sender: "HR Department", title: "Holiday Notice", time: "2:00 PM" },
  ]);

  const [selectedEmails, setSelectedEmails] = useState([]);

  const handleCheckboxChange = (id) => {
    setSelectedEmails((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((emailId) => emailId !== id)
        : [...prevSelected, id]
    );
  };

  const handleSelectAll = () => {
    if (selectedEmails.length === emails.length) {
      setSelectedEmails([]);
    } else {
      setSelectedEmails(emails.map((email) => email.id));
    }
  };

  const handleDeleteSelected = () => {
    const updatedEmails = emails.filter((email) => !selectedEmails.includes(email.id));
    setEmails(updatedEmails);
    setSelectedEmails([]);
  };

  return (
    <div className={`main-content ${isDarkMode ? 'dark' : 'light'}`}>
      {/* Themed Action Buttons */}
      <div className="actions-grid">
        <input
          type="checkbox"
          className="select-all-checkbox"
          onChange={handleSelectAll}
          checked={selectedEmails.length === emails.length && emails.length > 0}
        />
        <button className="action-button" onClick={handleDeleteSelected}>
          <FaTrash /> Delete
        </button>
        <button className="action-button"><FaArchive /> Archive</button>
        <button className="action-button"><FaExclamationTriangle /> Report Spam</button>
        <button className="action-button"><FaEnvelope /> Mark as Read</button>
      </div>

      {/* Horizontal Tabs */}
      <div className="tabs-grid">
        <button className="tab active"><AiOutlineInbox /> Primary</button>
        <button className="tab"><AiOutlineTag /> Promotions</button>
        <button className="tab"><AiOutlineUser /> Social</button>
      </div>

      {/* Email List Content */}
      <div className="panel">
        <table className="emails-grid">
          <tbody>
            {emails.map((email) => (
              <tr key={email.id} className="email-row">
                <td>
                  <input
                    type="checkbox"
                    checked={selectedEmails.includes(email.id)}
                    onChange={() => handleCheckboxChange(email.id)}
                  />
                </td>
                <td><FaStar className="icon starred" title="Star this email" /></td>
                <td className="sender">{email.sender}</td>
                <td className="title">{email.title}</td>
                <td className="time">{email.time}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default MainContent;
