import React from 'react';
import { FaInbox, FaStar, FaClock, FaExclamationCircle, FaTrash, FaBell, FaCalendarAlt } from 'react-icons/fa';
import '../Css/Sidebar.css';

const Sidebar = ({ onComposeClick, isDarkMode }) => {
  const handleClick = (section) => {
    alert(`${section} clicked`); // Replace with the actual action
  };

  return (
    <div className={`sidebar ${isDarkMode ? 'dark' : 'light'}`}>
      <button className="compose-btn" onClick={onComposeClick}>
        <span className="compose-icon">+</span> Compose
      </button>
      <div className="sidebar-sections">
        <div className="sidebar-item" onClick={() => handleClick('Inbox')}>
          <FaInbox /> Inbox
        </div>
        <div className="sidebar-item" onClick={() => handleClick('Starred')}>
          <FaStar /> Starred
        </div>
        <div className="sidebar-item" onClick={() => handleClick('Snoozed')}>
          <FaClock /> Snoozed
        </div>
        <div className="sidebar-item" onClick={() => handleClick('Spam')}>
          <FaExclamationCircle /> Spam
        </div>
        <div className="sidebar-item" onClick={() => handleClick('Bin')}>
          <FaTrash /> Bin
        </div>
        <div className="sidebar-item" onClick={() => handleClick('Important')}>
          <FaBell /> Important
        </div>
        <div className="sidebar-item" onClick={() => handleClick('Scheduled')}>
          <FaCalendarAlt /> Scheduled
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
